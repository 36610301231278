import React, { useState, useEffect } from "react";

const testimonialsData = [
  {
    id: 1,
    name: "Clara C. Lagat",
    position: "CEO & Founder",
    company: "Transfrontières Agencies Limited",
    testimonial: '"Our Software & IT services agent, Ra\'Asis, always have the right software solutions. Their wide range of  expertise in tech. and the passion they show in every project they undertake have helped us achieve remarkable results with our Software & CIT clients."'
  },
  {
    id: 2,
    name: "Young O. Samwel",
    position: "Chairman",
    company: "Mombasa Infernos Softball & Baseball Club",
    testimonial: '"Ra\'Asis Software offers the best graphics design solutions in the modern graphics design industry. We have partnered with them for 2 years now, and during that period, we have enjoyed quick and efficient services with pure quality delivery on all our orders. We as Infernos Softball & Baseball Club are happy to have them as our graphics and software partners."'
  },

  {
    id: 3,
    name: "George Kibiwot Sing'oei",
    position: "Managing Director",
    company: "Mugeiyot Kenya Holdings Ltd.",
    testimonial: '"In partnership with Ra\'Asis Software, we have advanced our business in terms of brand enhancement & optimization. They are playing a big part in the success of our company through professional graphics design, social media management & recently in planning for our future software development projects."'
  },
  
  {
    id: 4,
    name: "Onesmus Maritim",
    position: "Chairman & Co-Founder",
    company: "The Oaks Africa Foundation",
    testimonial: '"In the past year, we have been working closely with Ra\'Asis Software for our digital transformation needs and the impact on our foundation has been nothing short of transformative. From the outset, their team demonstrated a deep understanding of what we required. We now have an essential web platform to conduct our operations online, and we are looking forward to much more."'
  },

  {
    id: 5,
    name: "Francis Karugu",
    position: "Sports Director & Manager",
    company: "MMU Softball Club",
    testimonial: '"Working with Ra\'Asis Software has been an absolute pleasure for us. Their expertise and creativity in logo design and jersey customization has elevated our brand and image to new heights. Their designs have left a lasting impression, making them an invaluable partner in our journey."'
  },

  // Will add more testimonials as needed
];

const ClientTestimonialsSection = () => {
    const [testimonials, setTestimonials] = useState(testimonialsData);
    const [selectedTestimonial, setSelectedTestimonial] = useState(testimonialsData[Math.floor(testimonialsData.length / 2)]);

    const handleTestimonialClick = (testimonial) => {
        const index = testimonials.findIndex((item) => item.id === testimonial.id);
        const newTestimonials = [...testimonials];
        const clickedTestimonial = newTestimonials.splice(index, 1)[0]; // Remove the clicked testimonial from the array
        const centerIndex = Math.floor(newTestimonials.length / 2); // Calculate the center index
        newTestimonials.splice(centerIndex, 0, clickedTestimonial); // Insert the clicked testimonial at the center

        setTestimonials(newTestimonials);
        setSelectedTestimonial(testimonial);
  };

  return (
    <div className="client-testimonials-section">
      <div className="testi-wrapper">
        <div className="client-testimonials-intro">
          <h2>If You Are Digital Happy We Are Happy</h2>
          <p>Our digital products have put smiles on many business & brand owners on their paths to success. Here are some words from some of the top minds we work with on how our services have impacted their brands.</p>
        </div>
        <div className="client-testimonials">
          <div className="client-images">
            {testimonials.map((testimonial) => (
              <img
                key={testimonial.id}
                src={`/assets/client_${testimonial.id}.jpg`}
                alt={testimonial.name}
                className={testimonial === selectedTestimonial ? "active" : ""}
                onClick={() => handleTestimonialClick(testimonial)}
              />
            ))}
          </div>
          <div className="testimonial-content">
            <h3>{selectedTestimonial.name}</h3>
            <h4>{selectedTestimonial.position}, {selectedTestimonial.company}</h4>
            <p>{selectedTestimonial.testimonial}</p>
          </div>
        </div>
        <MobileTestimonials />
      </div>
    </div>
  );
};

const MobileTestimonials = () => {

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTestimonialIndex(prevIndex =>
        prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
      );
    }, 30000);

    // Clean up function to clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="mobile-testimonials">
      <img src={`/assets/client_${testimonialsData[currentTestimonialIndex].id}.jpg`} alt={testimonialsData[currentTestimonialIndex].name} />
      <h3>{testimonialsData[currentTestimonialIndex].name}</h3>
      <h4>{testimonialsData[currentTestimonialIndex].position}, {testimonialsData[currentTestimonialIndex].company}</h4>
      <p>{testimonialsData[currentTestimonialIndex].testimonial}</p>
    </div>
  );
};

export default ClientTestimonialsSection;
