import React, { useEffect } from "react";
import ReactGA from "react-ga4";

const OurTeam = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Our Team | Ra'Asis",
    });
  });

  return (
    <>
      <section className="about-team">
        <div className="about-team-content">
          <div className="about-team-intro">
            <h2>The Ra'Asis Team</h2>
            <p>Here is the exceptional group of tech. and marketing enthusiasts that are the driving force of our Full-Stack Agile Software Engineering processes and capabilities</p>
          </div>
          <div className="team-members">
            <div className="team-member">
              <img src="/assets/allan_kimutai_tum.webp" alt="Allan Kimutai Tum"/>
              <h3>Allan Kimutai Tum</h3>
              <p>Lead Software Engineer</p>
            </div>
            <div className="team-member">
              <img src="/assets/fridah_kamwara.webp" alt="Fridah Kamwara"/>
              <h3>Fridah Kamwara</h3>
              <p>Communications Officer</p>
            </div>
            <div className="team-member">
              <img src="/assets/ronald_kolum.webp" alt="Ronald Kolum"/>
              <h3>Ronald Kolum</h3>
              <p>Software Engineer</p>
            </div>
            <div className="team-member">
              <img src="/assets/jelimo_birir.webp" alt="Jelimo Birir"/>
              <h3>Jelimo Birir</h3>
              <p>Front-End Developer</p>
            </div>
            <div className="team-member">
              <img src="/assets/male_corp.jpg" alt="Kilonzi Stanley"/>
              <h3>Kilonzi Stanley</h3>
              <p>Cyber Security Analyst</p>
            </div>
            <div className="team-member">
              <img src="/assets/cynthia_thoithi.webp" alt="Cynthia Thoithi"/>
              <h3>Cynthia Thoithi</h3>
              <p>Front-End Developer</p>
            </div>
            <div className="team-member">
              <img src="/assets/dominic_kipkosgei.webp" alt="Dominic K. Bor"/>
              <h3>Dominic K. Bor</h3>
              <p>Sales & Marketing</p>
            </div>
            <div className="team-member">
              <img src="/assets/male_corp.jpg" alt="Evans Saina"/>
              <h3>Evans Saina</h3>
              <p>Project Manager</p>
            </div>
            <div className="team-member">
              <img src="/assets/antoncim_kipkorir.webp" alt="Antoncim Kipkorir"/>
              <h3>Antoncim Kipkorir</h3>
              <p>Sales & Marketing</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurTeam;