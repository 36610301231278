import React from "react";
import ServicesOverviewSection from "./ServicesOverviewSection";

const ProjectProcessSection = () => {
  
    return (
      <div className="large-gradientbg">
        <div className="process-section">
          <div className="process-heading">
            <h2>Large Or Small, Your Project Will Follow Agile Scrum...</h2>
          </div>
          <div className="process-content">
            <img src="/assets/scrum_process.png" alt="an illustration of the Agile framework, SCRUM employed in a project"/>
          </div>
        </div>
        <ServicesOverviewSection />
      </div>
    );
  }

  export default ProjectProcessSection;