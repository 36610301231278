import React, { useState, useEffect} from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

function ClientRecognitionSection() {

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  return (
    <div className="client-recognition-section">
        <div className="client-recognition-left">
          <RotatingHeader />
          <p>From individual brands and startups to leading industry enterprises, Our Client Community keeps gaining new members. What makes them stay at Ra"Asis is our top-notch IT talent that facilitates upscaling of their teams and building long-term custom software solutions. We know you have your unique needs as an individual or group and so we deliver a full spectrum of integrated design, development, and maintenance services for automation and modernization.</p>
          <img src="/assets/clients_comm.png" alt="An illustration of a connected Ra'Asis Software Client Community" />
        </div>
        <div className="client-recognition-right">
          <div className="brand-logos">
            <div><img src="/assets/transfro_logo.svg" alt="Transfrontières Agencies" /></div>
            <div><img src="/assets/oaks_africa.svg" alt="Oaks Africa Foundation" /></div>
            <div><img src="/assets/mugeiyot.svg" alt="Mugeiyot Supermarkets" /></div>
            <div><img src="/assets/infernos.png" alt="Mombasa Infernos Baseball & Softball" /></div>
            <div><img src="/assets/taai_logo.svg" alt="Taai Media" /></div>
            <div><img src="/assets/better_sips.svg" alt="Better Sips Distributors" /></div>
            <div><img src="/assets/kaytech.svg" alt="KayTech CompVilla" /></div>
            <div><img src="/assets/zeway_logo.svg" alt="Zeway Builders" /></div>
            <div><img src="/assets/mmu_stormerz.png" alt="MMU Stormerz Softball" /></div>
            <div><img src="/assets/katf_logo.png" alt="The Kenya Association of Teachers of French" /></div>
            <div><img src="/assets/glc_logo.webp" alt="Great Lakes Crisis Blog" /></div>
            <div><img src="/assets/mwalimu_fred_logo.webp" alt="Mwalimu Fred Online" /></div>
          </div>
          <div className="client-recognition-btn">
            <Link to="/contact" id="join_comm" onClick={handleLinkClick}>Join Our Client Community</Link>
          </div>
        </div>
    </div>
  );
}

function RotatingHeader(){
    const phrases = [
        "We Empower Brands Across Kenya, Africa & The World",
        "In Equal Measure They Empower Us To Become The Best",
        "Join Our Client Community Today For Mutual Growth",
      ];
    
      const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    
      useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 10000); // Will adjust accordingly later
    
        return () => clearInterval(intervalId);
      });
    
      return (
        <h2>
          {phrases[currentPhraseIndex]}
        </h2>
      );
}

export default ClientRecognitionSection;
