import React from "react";

const RecentProjectsSection = () => {
  return (
    <div className="recent-projects-section">
      <div className="recent-projects-intro">
        <h2>Here's What We've Designed, Built & Deployed Recently</h2>
        <p>We empower startups and upcoming brands, with exquisite designs and robust digital software products that propel them to guaranteed success in their niche.</p>
      </div>
      <div className="rp-grid-area">
        <div className="recent-projects-grid">
            <div className="recent-project-item">
                <img src="/assets/infernos_proj_away.png" alt="Mombasa Infernos Away Jersey Project"/>
                <img className="gif" src="/assets/infernos_proj_away.gif" alt="Mombasa Infernos Away Jersey Project"/>
            </div>
        </div>
        <div className="recent-projects-grid">
            <div className="recent-project-item">
                <img src="/assets/stormerz_proj_away.png" alt="MMU Stormerz Away Jersey Project"/>
                <img className="gif" src="/assets/stormerz_proj_away.gif" alt="MMU Stormerz Away Jersey Project"/>
            </div>
        </div>
        <div className="recent-projects-grid">
            <div className="recent-project-item">
                <img src="/assets/katf_proj.png" alt="KATF Web Project"/>
                <img className="gif" src="/assets/katf_proj_long.gif" alt="KATF Web Project"/>
            </div>
        </div>
        <div className="recent-projects-grid">
            <div className="recent-project-item">
                <img src="/assets/mwalimufred_proj.png" alt="Mwalimu Fred Online eLearning Project"/>
                <img className="gif" src="/assets/mwalimufred_proj_long.gif" alt="Mwalimu Fred Online eLearning Project"/>
            </div>
        </div>
      </div>
    </div>
  );
}

export default RecentProjectsSection;
